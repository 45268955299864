import {
  CashOrderData,
  CreateOrderPayload,
  Order,
  OrderStatus,
} from "../types";
import { http } from "../utils/http";
import { wp } from "../utils/wp";

export const orders = {
  createOrder: async (payload: CreateOrderPayload, email?: string) =>
    http
      .post<Order>(email ? `/orders?email${email}` : "/orders", payload)
      .then((response) => response.data),
  cancelOrder: async (id: string) =>
    http
      .put<Order>(`/orders/${id}`, {
        status: OrderStatus.CANCELED,
      })
      .then((response) => response.data),
  createWpOrder: (order: CashOrderData) => wp.post("/orders", order),
};
