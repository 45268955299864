import axios from "axios";
// WooCommerce API Endpoint
const apiUrl = "https://itadmit.com/wp-json/wc/v3";

// WooCommerce API Credentials
const consumerKey = "ck_62fcb31404ed08b73e86d839188eb9714825dc47";
const consumerSecret = "cs_2f9cc3ca5464fc7cbfd772589785b61aa25855ba";

// Prepare authentication string
const authString = btoa(`${consumerKey}:${consumerSecret}`);

// Headers for the request
const headers = {
  Authorization: `Basic ${authString}`,
};

export const wp = axios.create({
  baseURL: apiUrl,
  headers: headers,
});
