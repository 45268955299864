import dayjs from "dayjs";
import { PickupPoint } from "../../types";

const getRecurer = (startDate: Date) => {
  const sdate = dayjs(startDate).format("MM/DD/YYYY");
  const hours = dayjs(startDate).get("hours");
  const minutes = dayjs(startDate).get("minutes");

  return dayjs()
    .recur({
      start: sdate,
      end: "10/10/2024",
    })
    .every(2, "week")
    .days()
    .all()
    .slice(0, 20)
    .map((d) => d.set("hours", hours).set("minutes", minutes).toDate());
};

type GetClosestDateOptions = {
  addHours?: number;
};
function getClosestDateByNow(
  dates: Date[],
  options: GetClosestDateOptions = {}
) {
  return (
    dates.find((d) => {
      return dayjs(d)
        .startOf("day")
        .add(options?.addHours || 13, "h")
        .isAfter(dayjs());
    }) || dates[0]
  );
}

export const getClosestPointByNow = (point: PickupPoint) => {
  const date = getRecurer(point.date);
  const closestDate = getClosestDateByNow(date);
  return {
    ...point,
    date: closestDate,
  };
};
