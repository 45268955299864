import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";
import { dataProvider } from "../../dp";
import { PickupPoint } from "../../types";
import { getSelectedCityId } from "../cities/selectors";

type State = {
  isLoading: boolean;
  data: PickupPoint[];
};

const initialState: State = {
  isLoading: false,
  data: [],
};

export const getPickupPointsThunk = createAsyncThunk(
  "pickups/getPickupPoints",
  async (_, options) => {
    const state = options.getState() as RootState;
    const cityId = getSelectedCityId(state);
    try {
      if (!cityId) return [];
      return await dataProvider.cities.getPickupPoints(cityId);
    } catch (error) {
      return [];
    }
  }
);

export const pickupsSlice = createSlice({
  name: "pickups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPickupPointsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPickupPointsThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getPickupPointsThunk.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
