import styled from "@emotion/styled";
import Add from "@mui/icons-material/Add";
import Info from "@mui/icons-material/AddCircleOutlineSharp";
import Remove from "@mui/icons-material/Remove";
import { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { setLineItems } from "../../store/stepper/steperSlice";
import { appTheme } from "../../theme";
import type { Product } from "../../types";

const MoreInfo = styled.div`
  padding: 5px 0;
  color: #022246;
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 5px;
`;
const PrdocutDetails = styled.pre<{ isOpen?: boolean }>`
  font-weight: 400;
  display: flex;
  gap: 10px;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
  border-top: 0.0625rem dashed rgba(32, 33, 37, 0.12);
  color: #829b73;
  overflow: hidden;
  padding: 0 10px;
  height: ${({ isOpen }) => (isOpen ? "auto" : "28px")};
  /* padding: ${({ isOpen }) => (isOpen ? "0 10px" : "0")}; */
`;

const PrdocutAddToCart = styled.div`
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  border-top: 0.0625rem dashed rgba(32, 33, 37, 0.12);
  color: #829b73;
  padding: 0 10px;
`;

const StyledProduct = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;

  background-color: #f5f5f5;
  width: 100%;

  overflow: hidden;
  border-radius: 0.5rem;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.125rem 0px,
    rgba(0, 0, 0, 0.12) 0px 0.125rem 0.125rem 0px;
`;

const ProductFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const ProductPrice = styled.div<{
  discount?: number;
}>`
  width: 30px;
  height: 0;
  padding: 1rem;
  display: flex;
  background: #72975829;
  color: ${(props) => (props.discount ? "red" : "#829b73")};
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
`;

const DiscountPrice = styled.div`
  width: 30px;
  height: 0;
  padding: 1rem;
  display: flex;
  opacity: 0.8;
  color: #829b73;
  text-decoration: line-through;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductTitle = styled.h1`
  font-size: ${appTheme.fontSize[1]};
  padding: 0;
  margin: 0;
`;

const ProductDesc = styled.p`
  font-size: ${appTheme.fontSize[0]};
  padding: 0;
  margin: 0;
`;

const ProductButtons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const ProductButton = styled.button`
  height: 2rem;
  border: none;
  background-color: #829b73;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProductTotal = styled.div`
  display: flex;
  font-weight: bold;
  font-size: ${appTheme.fontSize[2]};
  align-items: center;
  gap: 4px;
`;

const ProductImage = styled(ImageGallery)`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;
type ProductProps = {
  product: Product;
};
export function ProductItem({ product }: ProductProps) {
  const dispatch = useAppDispatch();
  const { lineItems } = useAppSelector(getStepperSlice);
  const [isOpen, setIsOpen] = useState(false);
  const item = lineItems.find((item) => item.productId === product.uuid);
  return (
    <StyledProduct>
      <ProductImage
        showFullscreenButton={false}
        showThumbnails={false}
        autoPlay
        useTranslate3D={false}
        items={product.images.map((image) => ({
          original: image,
        }))}
      />
      <ProductFooter>
        <ProductInfo>
          <ProductTitle>{product.title}</ProductTitle>
          <ProductDesc>{product.description}</ProductDesc>
        </ProductInfo>
        <div>
          {product.discount && product.discount > 0 ? (
            <DiscountPrice>
              {product.price}
              <span>₪</span>
            </DiscountPrice>
          ) : null}
          <ProductPrice discount={product.discount}>
            {product.discount || product.price}
            <span>₪</span>
          </ProductPrice>
        </div>
      </ProductFooter>
      <PrdocutAddToCart>
        <ProductTotal>
          <span>כמות:</span>
          <span>{item?.quantity ?? 0} </span>
        </ProductTotal>
        <ProductButtons>
          <ProductButton
            onClick={() => {
              dispatch(
                setLineItems([
                  ...lineItems.filter(
                    (item) => item.productId !== product.uuid
                  ),
                  {
                    productId: product.uuid,
                    quantity: (item?.quantity ?? 0) + 1,
                  },
                ])
              );
            }}
          >
            <Add /> הוסף מארז לסל
          </ProductButton>

          <ProductButton
            onClick={() => {
              const nextVal = Math.max((item?.quantity ?? 0) - 1, 0);
              dispatch(
                setLineItems(
                  [
                    ...lineItems.filter(
                      (item) => item.productId !== product.uuid
                    ),
                  ].concat(
                    nextVal > 0
                      ? [{ productId: product.uuid, quantity: nextVal }]
                      : []
                  )
                )
              );
            }}
          >
            <Remove />
          </ProductButton>
        </ProductButtons>
      </PrdocutAddToCart>
      <PrdocutDetails isOpen={isOpen}>
        <MoreInfo onClick={() => setIsOpen(!isOpen)}>
          <Info />
          מה מכיל המארז?
        </MoreInfo>
        <ul>
          {product.items.map((item) => (
            <li key={item.uuid}>{item.label}</li>
          ))}
        </ul>
      </PrdocutDetails>
    </StyledProduct>
  );
}
