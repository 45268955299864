import { Customer, WpCustomer } from "../types";
import { http } from "../utils/http";
import { wp } from "../utils/wp";

export const customer = {
  getCustomerByPhone: async (phone: string) => {
    try {
      const response = await http.get<Customer>(`/users/${phone}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createCustomer: async (customer: {
    fullname: string;
    username: string;
    password: string;
  }) => {
    try {
      const response = await http.post<Customer>(`/users`, customer);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateCustomer: async (userId: string, customer: Partial<Customer>) => {
    delete customer.uuid;
    try {
      const response = await http.put<Customer>(`/users/${userId}`, customer);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  getWpCustomerByPhone: async (phone: string) => {
    try {
      const response = await wp.get<WpCustomer[]>(`/customers?search=${phone}`);
      return response.data?.[0];
    } catch (error) {
      console.log(error);
    }
  },
  createWpCustomer: async (customer: Partial<WpCustomer>) => {
    try {
      const response = await wp.post<WpCustomer>(`/customers`, customer);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
};
