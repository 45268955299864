import { dataProvider } from "../../dp";
import { getSelectedCity } from "../../store/cities/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getOrder, getStepperSlice } from "../../store/stepper/selectors";
import {
  setCurrentStep,
  setLoading,
  setOrderId,
  setPaymentLink,
} from "../../store/stepper/steperSlice";
import { getSelectedUserShipment } from "../../store/userShipments/selectors";
import { Errors, PaymentMethod, Steps } from "../../types";
import { notifyError } from "../../utils/notifications";
import { useCreateCashOrder } from "./useCreateCashOrder";

export function useOrder() {
  const dispatch = useAppDispatch();
  const orderPayload = useAppSelector(getOrder);
  const { email, paymentMethod } = useAppSelector(getStepperSlice);
  const userShipment = useAppSelector(getSelectedUserShipment);
  const selectedCity = useAppSelector(getSelectedCity);
  const { createCashOrder } = useCreateCashOrder();
  const createOrder = async (note: string) => {
    if (!orderPayload) return notifyError(Errors.CreateOrderNoPayload);

    dispatch(setLoading(true));
    if (paymentMethod === PaymentMethod.CASH) {
      try {
        await createCashOrder();
      } catch (error) {
        notifyError(Errors.CreateOrderFailed);
      }
    } else {
      try {
        const order = await dataProvider.orders.createOrder(
          {
            ...orderPayload,
            note,
          },
          email
        );

        const { uuid } = order;

        const { payment_page_link } =
          await dataProvider.payments.getPaymentLink(uuid);

        dispatch(setPaymentLink(payment_page_link));
        dispatch(setOrderId(uuid));
        dispatch(setCurrentStep(Steps.PAYMENT));
      } catch (error) {
        notifyError(Errors.CreateOrderFailed);
      }
    }
    if (selectedCity?.label || userShipment?.city) {
      dataProvider.customer
        .updateCustomer(orderPayload.userId, {
          city: selectedCity?.label || userShipment?.city.label,
        })
        .then(() => {})
        .catch(() => {});
    }

    dispatch(setLoading(false));
  };

  return { createOrder };
}
