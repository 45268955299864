import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { getUserShipmentsThunk } from "../../store/userShipments";
import {
  setCurrentStep,
  setUserShipmentId,
} from "../../store/stepper/steperSlice";
import { Steps } from "../../types";

export function useUserShipment() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserShipmentsThunk())
      .unwrap()
      .then((action) => {
        if (action.length === 0) {
          dispatch(setCurrentStep(Steps.ADD_USER_SHIMPENT));
        } else {
          dispatch(setUserShipmentId(action[0].uuid));
        }
      });
  }, [dispatch]);
}
