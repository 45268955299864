import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResetStepper } from "./stepper/steperSlice";
import { resetUiState } from "./ui/uiSlice";

export const resetThunk = createAsyncThunk(
  "app/resetThunk",
  async (_, { dispatch }) => {
    dispatch(setResetStepper());
    dispatch(resetUiState());
  }
);
