import { useAppSelector } from "../../store/hooks";
import { getSelectedUserShipment } from "../../store/userShipments/selectors";
import { Table } from "./commons";

export function DeliverySummary() {
  const userShipment = useAppSelector(getSelectedUserShipment);
  return (
    <>
      <h3>פרטי משלוח</h3>
      <Table.Core>
        <Table.Row>
          <Table.Cell>שם מלא</Table.Cell>
          <Table.Cell>{userShipment?.name}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>עיר</Table.Cell>
          <Table.Cell>{userShipment?.city?.label}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>כתובת</Table.Cell>
          <Table.Cell>
            {userShipment?.street1} {userShipment?.entrance}, דירה{" "}
            {userShipment?.appartment}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>טלפון</Table.Cell>
          <Table.Cell>{userShipment?.phone}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>דואר אלקטרוני</Table.Cell>
          <Table.Cell>{userShipment?.email}</Table.Cell>
        </Table.Row>
      </Table.Core>
    </>
  );
}
