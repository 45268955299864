import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Steper } from "./Steper";
import { StepComplete } from "./Steper/StepComplete";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Layout } from "./components/Layout";
import { About } from "./pages/About";
import { ContactUs } from "./pages/ContactUs";
import { Terms } from "./pages/Terms";
import { ReduxProvider } from "./store/provider";

export function Client() {
  return (
    <ReduxProvider>
      <Layout.Core>
        <Layout.Header>
          <Header />
        </Layout.Header>
        <Layout.Main>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Steper />} />
            <Route path="/success" element={<StepComplete />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Layout.Main>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout.Core>
    </ReduxProvider>
  );
}
