import { dataProvider } from "../../dp";
import { useAppDispatch } from "../../store/hooks";
import {
  setCurrentStep,
  setCustomerName,
  setCustomerPhone,
  setLoading,
  setuserId,
} from "../../store/stepper/steperSlice";
import { Errors, Steps } from "../../types";

import { notifyError } from "../../utils/notifications";

type CreateCustomerProps = {
  fullname: string;
  phone?: string;
};

type UpdateCustomerProps = {
  userId: string;
  fullname: string;
  phone?: string;
};

export function useCustomer() {
  const dispatch = useAppDispatch();

  const validateCustomerByNumber = async (phone: string) => {
    dispatch(setLoading(true));
    try {
      const customer = await dataProvider.customer.getCustomerByPhone(phone);

      if (customer?.username) {
        dispatch(setCustomerPhone(customer.username));
        dispatch(setCustomerName(customer.fullname));
        dispatch(setuserId(customer.uuid));
        dispatch(setCurrentStep(Steps.SELECT_PRODUCTS));
      } else {
        dispatch(setCustomerPhone(phone));
        dispatch(setCurrentStep(Steps.ASK_FOR_NAME));
      }
    } catch (e) {
      notifyError(Errors.ValidateUser);
    }

    dispatch(setLoading(false));
  };

  const createCustomer = async ({ fullname, phone }: CreateCustomerProps) => {
    if (phone) {
      dispatch(setLoading(true));
      try {
        const customer = await dataProvider.customer.createCustomer({
          fullname,
          username: phone,
          password: phone,
        });

        if (customer) {
          dispatch(setCustomerName(customer.fullname));
          dispatch(setCustomerPhone(customer.username));
          dispatch(setuserId(customer.uuid));
          dispatch(setCurrentStep(Steps.SELECT_PRODUCTS));
        }
      } catch (e) {
        notifyError(Errors.CreateUserFaild);
      }

      dispatch(setLoading(false));
    }
  };

  const updateCustomer = async ({
    fullname,
    phone,
    userId,
  }: UpdateCustomerProps) => {
    if (phone && userId) {
      dispatch(setLoading(true));
      try {
        const customer = await dataProvider.customer.updateCustomer(userId, {
          fullname,
        });

        if (customer) {
          dispatch(setCustomerName(customer.fullname));
          dispatch(setCustomerPhone(customer.username));
          dispatch(setuserId(customer.uuid));
          dispatch(setCurrentStep(Steps.SELECT_PRODUCTS));
        } else {
          notifyError(Errors.UpdateUserFaild);
        }
      } catch (e) {
        notifyError(Errors.UpdateUserFaild);
      }
      dispatch(setLoading(false));
    }
  };
  return { validateCustomerByNumber, createCustomer, updateCustomer };
}
