import "./styles.css";

type InputProps = {
  style?: any;
  inputmode?: any;
  pattern?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  type?:
    | "text"
    | "phone"
    | "password"
    | "number"
    | "date"
    | "month"
    | "week"
    | "checkbox"
    | "time"
    | "email";
};

export function Input({
  value,
  onChange,
  placeholder,
  type,
  pattern,
  inputmode,
  style,
}: InputProps) {
  return (
    <input
      inputMode={inputmode}
      pattern={pattern}
      className="input"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
      style={style}
    />
  );
}
