import styled from "@emotion/styled";
import { useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { PaymentMethod } from "../../types";

const StyledSelection = styled.div`
  gap: 5px;
`;

export function CashNote() {
  const { paymentMethod } = useAppSelector(getStepperSlice);

  return (
    <div>
      {paymentMethod === PaymentMethod.CASH && (
        <StyledSelection
          style={{
            backgroundColor: "#f5f5f5",
            padding: "5px 10px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            שימו לב!
          </span>{" "}
          בחודש האחרון המון אנשים בחרו באפשרות זו ולא הגיעו. הזמנתם נזרקה לפח
          ונגרם לנו הפסדים כבדים. נבקש את התחשבותכם, נא להגיע בוודאות לקחת את
          ההזמנה.
          <br />
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            סומכים עליך! 🙏
          </span>
        </StyledSelection>
      )}
    </div>
  );
}
