// @ts-nocheck
import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { RootState } from "..";
import { getClosestPointByNow } from "./utils";

const getSelectedPickupPointId = (state: RootState) =>
  state.stepper.pickupPointId;

export const getPickupPoints = (state: RootState) => state.pickups.data;

export const getPickupPointsDropdown = createSelector(
  getPickupPoints,
  (pickupPoints) =>
    pickupPoints
      ?.filter((pickupPoint) => pickupPoint.visible)
      .map(getClosestPointByNow)
      .sort((a, b) => {
        return dayjs(a.date, "DD/MM/YYYY").isAfter(dayjs(b.date, "DD/MM/YYYY"))
          ? 1
          : -1;
      })
      .map((p) => ({
        label: `${p.label} - [${p.address}] - [${dayjs(p.date).format(
          "DD/MM/YYYY"
        )}]`,
        value: p.uuid,
      })) ?? []
);

export const getSelectedPickupPoint = createSelector(
  getSelectedPickupPointId,
  getPickupPoints,
  (pickupPointId, pickupPoints) => {
    const p = pickupPoints?.find(
      (pickupPoint) => pickupPoint.uuid === pickupPointId
    );
    return p ? getClosestPointByNow(p) : undefined;
  }
);
