import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getStepperSlice } from "../stepper/selectors";

export const getUserShipmentsSlice = (state: RootState) => state.userShipments;
export const getUserShipments = (state: RootState) => state.userShipments.data;

export const getUserShipmentsDropDown = createSelector(
  getUserShipments,
  (shipments) => {
    return shipments.map((shipment) => ({
      value: shipment.uuid,
      label: `${shipment.name} - ${shipment.city?.label}, ${shipment.street1} ${shipment.entrance}, דירה ${shipment.appartment}.`,
    }));
  }
);

export const getSelectedUserShipment = createSelector(
  getStepperSlice,
  getUserShipments,
  (stpper, shipments) => shipments.find((s) => s.uuid === stpper.userShipmentId)
);
