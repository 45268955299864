import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { dataProvider } from "../../dp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getSelectedPickupPoint } from "../../store/pickups/selectors";
import { getProducts } from "../../store/products/selectors";
import { getStepperSlice } from "../../store/stepper/selectors";
import { setOrderId } from "../../store/stepper/steperSlice";
import { CashOrderData, LineItem, Product, ShipmentMethod } from "../../types";

const WP_PORDUCTS_MAP = {
  GREEK: 7407,
  SPICY: 7406,
  CLASSIC: 885,
};

const getProductId =
  (products: Product[]) => (item: Pick<LineItem, "productId" | "quantity">) => {
    const foundedProduct = products.find(
      (product) => product.uuid === item.productId
    );
    if (!foundedProduct) return 885;
    if (foundedProduct.title.includes("פיקנטי")) return WP_PORDUCTS_MAP.SPICY;
    if (foundedProduct.title.includes("יווני")) return WP_PORDUCTS_MAP.GREEK;
    return 885;
  };

export const useCreateCashOrder = () => {
  const dispatch = useAppDispatch();
  const point = useAppSelector(getSelectedPickupPoint);
  const products = useAppSelector(getProducts);
  const { shipmentMethod, phone, fullName, lineItems } =
    useAppSelector(getStepperSlice);
  const navigate = useNavigate();

  const createCashOrder = async () => {
    if (shipmentMethod === ShipmentMethod.PICKUP) {
      let wpUser;
      if (!phone || !fullName || !point) return;
      wpUser = await dataProvider.customer.getWpCustomerByPhone(phone);
      if (!wpUser) {
        wpUser = await dataProvider.customer.createWpCustomer({
          first_name: fullName,
          username: phone,
          email: `${phone}@form.com`,
        });
      }
      if (!wpUser) return;
      const pointDate = dayjs(point.date).subtract(2, "hours");
      const order: CashOrderData = {
        set_paid: true,
        customer_id: wpUser.id,
        status: "processing",
        billing: {
          first_name: wpUser.first_name,
          phone: wpUser.username,
        },
        shipping: {
          first_name: fullName,
          last_name: phone,
          address_1: `[${point.city.label}][${point.label}][${pointDate.format(
            "HH:mm"
          )}:${pointDate.add(point.duration, "minutes").format("HH:mm")}]`,

          city: `[${point.city.label}][${pointDate.format("DD/MM/YYYY")}]`,
          state: `${pointDate.format("HH:mm")}:${pointDate
            .add(point.duration, "minutes")
            .format("HH:mm")}`,
          country: point.city.label,
          postcode: point?.uuid || "",
          company: pointDate.format("DD/MM/YYYY"),
          phone: point.label,
        },
        line_items: lineItems.map((item) => {
          return {
            product_id: getProductId(products)(item), // TODO: get product id from WP
            quantity: item.quantity,
          };
        }),
      };

      const res = await dataProvider.orders.createWpOrder(order);
      dispatch(setOrderId(res.data.id));
      navigate(`/success?more_info_2=${res.data.id}`);
    }
  };

  return {
    createCashOrder,
  };
};
