import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { dataProvider } from "../../dp";
import { City, Errors } from "../../types";
import { notifyError } from "../../utils/notifications";

type State = {
  isLoading: boolean;
  data: City[];
  shipmentCities: City[];
};

const initialState: State = {
  isLoading: false,
  shipmentCities: [],
  data: [],
};

export const getCitiesThunk = createAsyncThunk("cities/getCities", async () => {
  try {
    const response = await dataProvider.cities.getCities();
    return response;
  } catch (error) {
    notifyError(Errors.GetCities);
    return [];
  }
});

export const getShipmentCitiesThunk = createAsyncThunk(
  "cities/getShipmentCities",
  async () => {
    try {
      const response = await dataProvider.cities.getShipmentCities();
      return response;
    } catch (error) {
      notifyError(Errors.GetCities);
      return [];
    }
  }
);

export const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCitiesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCitiesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getShipmentCitiesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShipmentCitiesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shipmentCities = action.payload;
      });
  },
});
