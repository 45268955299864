"use client";
import { useState } from "react";

import { Step } from "../Step";
import { useCustomer } from "../hooks/useCustomer";
import { useAppSelector } from "../../store/hooks";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { getStepperSlice } from "../../store/stepper/selectors";

export function StepAskForName() {
  const [fullname, setFullName] = useState("");
  const { createCustomer, updateCustomer } = useCustomer();
  const { phone, userId } = useAppSelector(getStepperSlice);

  return (
    <Step>
      <span>אנא הכנס את שמך המלא</span>
      <Input
        type="text"
        placeholder="שם מלא"
        value={fullname}
        onChange={setFullName}
      />
      <Button
        disabled={!fullname}
        onClick={() => {
          userId
            ? updateCustomer({ fullname, userId, phone })
            : createCustomer({
                phone,
                fullname,
              });
        }}
      >
        שלב הבא
      </Button>
    </Step>
  );
}
