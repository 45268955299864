import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import {
  setPickupPointId,
  setShipmentMethod,
} from "../../store/stepper/steperSlice";

import { setSelectedCityId } from "../../store/ui/uiSlice";
import { ShipmentMethod } from "../../types";

const options = [
  {
    label: "איסוף עצמי מנקודת חלוקה - חינם",
    value: ShipmentMethod.PICKUP,
  },
];

export const SelectShipmentMethod = () => {
  const dispatch = useAppDispatch();
  const { shipmentMethod } = useAppSelector(getStepperSlice);
  return (
    <>
      <Select
        isSearchable={false}
        aria-label="בחר שיטת משלוח"
        options={options}
        menuPosition={"fixed"}
        placeholder="בחר שיטת משלוח"
        onChange={(option) => {
          option?.value && dispatch(setShipmentMethod(option.value));
          dispatch(setPickupPointId());
          dispatch(setSelectedCityId());
        }}
        value={options.find((option) => option.value === shipmentMethod)}
      />
    </>
  );
};
