import Radio from "antd/es/radio";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import {
  setCurrentStep,
  setUserShipmentId,
} from "../../store/stepper/steperSlice";

import {
  getUserShipmentsDropDown,
  getUserShipmentsSlice,
} from "../../store/userShipments/selectors";
import styled from "@emotion/styled";
import { Steps } from "../../types";
import { setUpdateShipment } from "../../store/userShipments";

const UpdateButton = styled.div`
  color: blue;
  text-decoration: underline;
`;
export const SelectUserShipment = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(getUserShipmentsSlice);
  const { userShipmentId } = useAppSelector(getStepperSlice);
  const options = useAppSelector(getUserShipmentsDropDown) || [];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {options.map((option) => {
        return (
          <div key={option.value}>
            <Radio
              checked={option.value === userShipmentId}
              onChange={() => {
                dispatch(setUserShipmentId(option.value));
              }}
            >
              {option?.label}
            </Radio>
            <UpdateButton
              onClick={() => {
                dispatch(
                  setUpdateShipment(
                    data.find((shipment) => shipment.uuid === option.value)
                  )
                );
                dispatch(setCurrentStep(Steps.UPDATE_USER_SHIPMENT));
              }}
            >
              [ עדכון כתובת ]
            </UpdateButton>
          </div>
        );
      })}
    </div>
  );
};
