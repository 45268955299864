import styled from "@emotion/styled";
import ArrowBackIos from "@mui/icons-material/NavigateBeforeSharp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { setCurrentStep } from "../../store/stepper/steperSlice";
import { appTheme } from "../../theme";
import { Steps } from "../../types";

const StyledBackButton = styled.button`
  padding: 0;
  display: flex;
  border: none;
  outline: none;
  justify-content: flex-start;
  align-items: center;
  background: none;
  color: #fff;
  &:disabled {
    color: #ccc;
  }
  font-size: ${appTheme.fontSize[1]};
`;

const shouldBeVisble = (currentStep: Steps) => {
  switch (currentStep) {
    case Steps.SELECT_PRODUCTS:
      return true;
    default:
      return false;
  }
};

export function NextButton() {
  const { lineItems, currentStep } = useAppSelector(getStepperSlice);

  const dispatch = useAppDispatch();
  const handleGoNext = () => {
    switch (currentStep) {
      case Steps.SELECT_PRODUCTS:
        dispatch(setCurrentStep(Steps.SELECT_SHIPMENT));
        break;
    }
  };
  return (
    <div>
      {shouldBeVisble(currentStep) ? (
        <StyledBackButton
          onClick={handleGoNext}
          disabled={lineItems.length === 0}
        >
          <ArrowBackIos />
          <span>הבא</span>
        </StyledBackButton>
      ) : null}
    </div>
  );
}
