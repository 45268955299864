import styled from "@emotion/styled";
import dayjs from "dayjs";
import { getSelectedCity } from "../../store/cities/selectors";
import { useAppSelector } from "../../store/hooks";
import { getSelectedPickupPoint } from "../../store/pickups/selectors";

const StyledSelection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const StyledSelectionRow = styled.div`
  font-weight: bold;
`;

export function SelectedPickupPointInfo() {
  const selectedPoint = useAppSelector(getSelectedPickupPoint);
  const selectedCity = useAppSelector(getSelectedCity);
  return (
    <div>
      {selectedPoint && (
        <StyledSelection
          style={{
            backgroundColor: "#f5f5f5",
            padding: "5px 10px",
          }}
        >
          <StyledSelectionRow>הבחירה שלך:</StyledSelectionRow>
          <StyledSelectionRow>
            {selectedCity?.label}, {selectedPoint?.label}.
          </StyledSelectionRow>
          <StyledSelectionRow>
            כתובת: {selectedPoint?.address}.
          </StyledSelectionRow>
          <StyledSelectionRow>
            בשעה: {dayjs(selectedPoint.date).subtract(2, "h").format("HH:mm")}.
          </StyledSelectionRow>
          <StyledSelectionRow>
            תאריך: {dayjs(selectedPoint.date).format("DD/MM/YYYY")}.
          </StyledSelectionRow>
          <StyledSelectionRow>
            * בסיום ההזמנה תישלח הודעה עם כל הפרטים
          </StyledSelectionRow>
        </StyledSelection>
      )}
    </div>
  );
}
