export enum PaymentMethod {
  CASH = "CASH",
  CREDIT = "CREDIT",
}

export enum Steps {
  WELCOME = "WELCOME",
  ASK_FOR_NAME = "ASK_FOR_NAME",
  SELECT_PRODUCTS = "SELECT_PRODUCTS",
  SELECT_SHIPMENT = "SELECT_SHIPMENT",
  ADD_USER_SHIMPENT = "ADD_USER_SHIMPENT",
  UPDATE_USER_SHIPMENT = "UPDATE_USER_SHIPMENT",
  SUMMARY = "SUMMARY",
  PAYMENT = "PAYMENT",
}

export enum ShipmentMethod {
  SELF = "SELF",
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
}

export enum OrderStatus {
  CANCELED = "CANCELED",
  DELETED = "DELETED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_DELIVERY = "IN_DELIVERY",
}
type DbEntity<T> = T & {
  createdAt: Date;
  updatedAt: Date;
};
export type Customer = DbEntity<{
  uuid: string;
  fullname: string;
  username: string;
  role: "customer";
  city?: string;
}>;

export type City = DbEntity<{
  uuid: string;
  label: string;
  visible?: boolean;
}>;

export type PickupPoint = DbEntity<{
  uuid: string;
  address: string;
  cityId: string;
  date: Date;
  duration: number;
  label: string;
  line: number;
  visible: boolean;
  recurring: boolean;
  image?: string;
  city: City;
}>;

export type LineItem = DbEntity<{
  id: number;
  uuid: string;
  productId: string;
  quantity: number;
  product: Product;
  userId: string;
  orderId: string;
}>;

type Item = DbEntity<{
  id: number;
  uuid: string;
  title: string;
  label: string;
}>;

export type Product = DbEntity<{
  id: number;
  uuid: string;
  title: string;
  description: string;
  image?: string;
  sku: string;
  price: number;
  discount: number;
  images: string[];
  visible: boolean;
  order: number;
  items: Item[];
}>;

export type Order = DbEntity<{
  id: number;
  uuid: string;
  status: OrderStatus;
  shipmentMethod: ShipmentMethod;
  shipmentApprovalId?: string;
  userShipmentId?: string;
  userId: string;
  pickupPointId?: string;
  pickupDate: Date;
  paymentApprovalId?: string;
  note?: string;
}>;

export type UserShipment = DbEntity<{
  id: number;
  appartment: string;
  cityId: string;
  company: string;
  email: string;
  entrance: string;
  floor: string;
  name: string;
  phone: string;
  street1: string;
  street2: string;
  userId: string;
  uuid: string;
  visible: boolean;
  zip?: string;
  city: City;
}>;

// Payloads

type LineItemPayload = {
  productId: string;
  quantity: number;
  userId: string;
};

type PickupOrderPaylod = {
  status: OrderStatus.PENDING;
  shipmentMethod: ShipmentMethod.PICKUP;
  pickupPointId: string;
  pickupDate: Date;
  userId: string;
  note?: string;
  lineItems: LineItemPayload[];
};

type DeliveryOrderPaylod = {
  status: OrderStatus.PENDING;
  shipmentMethod: ShipmentMethod.DELIVERY;
  userShipmentId: string;
  userId: string;
  note?: string;
  lineItems: LineItemPayload[];
};

type SelfOrderPaylod = {
  status: OrderStatus.PENDING;
  shipmentMethod: ShipmentMethod.SELF;
  userId: string;
  note?: string;
  lineItems: LineItemPayload[];
};

export type CreateOrderPayload =
  | DeliveryOrderPaylod
  | PickupOrderPaylod
  | SelfOrderPaylod;

export enum Errors {
  CancelOrderFailed = 100,
  CreateOrderFailed = 110,
  CreateOrderNoPayload = 120,
  ValidateUser = 200,
  CreateUserFaild = 210,
  UpdateUserFaild = 220,
  UpdateUserShipmentTunk = 300,
  GetShipmentsFaild = 301,
  CreateUserShipment = 302,
  CreateUserShipmentTunk = 303,
  GetCities = 400,
  GetPickupOrderDetails = 401,
  GetDeliveryOrderDetails = 402,
  GetSelfOrderDetails = 403,
}

type CashLineItem = {
  product_id: number;
  quantity: number;
};
export type CashOrder = {
  id: number;
  set_paid: boolean;
  customer_id?: number;
  status: string;
  billing: {
    first_name?: string;
    phone: string;
  };
  shipping: {
    first_name?: string;
    last_name?: string;
    address_1: string;
    city: string;
    state: string;
    country: string;
    postcode: string;
    company: string;
    phone: string;
  };
  line_items: CashLineItem[];
};

export type CashOrderData = Omit<CashOrder, "id">;

export type WpCustomer = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  billing: {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    postcode: string;
    country: string;
    state: string;
    email: string;
    phone: string;
  };
  shipping: {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    postcode: string;
    country: string;
    state: string;
    phone: string;
  };
  is_paying_customer: boolean;
};
