// @ts-nocheck

import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "..";

export const getSelectedCityId = (state: RootState) => state.ui.selectedCityId;

export const getCities = (state: RootState) => state.cities.data;

export const getCitiesDropdown = createSelector(getCities, (cities) =>
  (
    cities
      ?.filter((city) => city.visible)
      .map((city) => ({ value: city.uuid, label: city.label })) ?? []
  ).sort((a, b) => a?.label.localeCompare(b?.label, "he"))
);

export const getSelectedCity = createSelector(
  getCities,
  getSelectedCityId,
  (cities, selectedCityId) =>
    cities?.find((city) => city.uuid === selectedCityId)
);

export const getShipmentCities = (state: RootState) =>
  state.cities.shipmentCities;
