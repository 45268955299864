import styled from "@emotion/styled";
import { Button } from "../../components/Button";

import { useForm } from "react-hook-form";
import { FormInput } from "../../components/FormInput";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCurrentStep,
  setPickupPointId,
  setShipmentMethod,
} from "../../store/stepper/steperSlice";

import { Loader } from "../../components/Loader";
import { SelectShipmentCity } from "../../components/SelectShipmentCity";
import { setSelectedCityId } from "../../store/ui/uiSlice";
import { updateUserShipmentThunk } from "../../store/userShipments";
import { getUserShipmentsSlice } from "../../store/userShipments/selectors";
import { Errors, Steps, UserShipment } from "../../types";
import { notifyError } from "../../utils/notifications";
import { ButtonsFooterNav } from "../ButtonsFooterNav";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StepUpdateUserShipment = () => {
  const { isLoading, update } = useAppSelector(getUserShipmentsSlice);
  const dispatch = useAppDispatch();
  const { handleSubmit, register, setValue, watch } = useForm<UserShipment>({
    defaultValues: update,
  });
  const onSubmit = async (data: UserShipment) => {
    try {
      dispatch(updateUserShipmentThunk(data))
        .unwrap()
        .then((shipment) => {
          if (shipment) {
            dispatch(setCurrentStep(Steps.SELECT_SHIPMENT));
          }
        });
    } catch (e) {
      notifyError(Errors.UpdateUserShipmentTunk);
      dispatch(setCurrentStep(Steps.SELECT_SHIPMENT));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loader />}
      <Wrapper>
        <h1>פרטים למשלוח עד הבית</h1>
        <h3>פרטי איש קשר</h3>
        <FormInput
          placeholder="שם מלא"
          {...register("name", { required: true })}
        />
        <FormInput
          placeholder="כתובת דואר אלקטרוני"
          type="email"
          {...register("email")}
        />
        <FormInput
          placeholder="טלפון"
          type="tel"
          {...register("phone", { required: true })}
        />
        <h3>כתובת משלוח</h3>

        <SelectShipmentCity
          onChane={(e) => {
            if (e?.uuid) {
              setValue("cityId", e.uuid);
            }
          }}
          selctedCityId={watch("cityId")}
        />

        <FormInput
          placeholder="רחוב"
          {...register("street1", { required: true })}
        />
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <FormInput
            placeholder="מספר בניין"
            {...register("entrance", { required: true })}
          />
          <FormInput
            placeholder="מספר דירה"
            {...register("appartment", { required: true })}
          />
          <FormInput placeholder="קומה" {...register("floor")} />
        </div>
        <h3>פרטים נוספים</h3>

        <FormInput
          placeholder="כתובת נוספת למקרה שלא תיהיו בבית"
          {...register("street2")}
        />

        <ButtonsFooterNav>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(setShipmentMethod());
              dispatch(setPickupPointId());
              dispatch(setSelectedCityId());
              dispatch(setCurrentStep(Steps.SELECT_SHIPMENT));
            }}
          >
            ביטול
          </Button>
          <Button type="submit">שמור</Button>
        </ButtonsFooterNav>
      </Wrapper>
    </form>
  );
};
