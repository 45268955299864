import { toast } from "react-toastify";
import { rappelsend } from "./rappelsend";
import { API_BASE_URL } from "../config";

type ErrorComponentProps = {
  issueId: number;
};
const ErrorComponent = ({ issueId }: ErrorComponentProps) => {
  return (
    <div
      style={{
        direction: "rtl",
      }}
    >
      אוי, נראה שמשהו לא תקין באתר שלנו.
      <br />
      אנא נסו שוב, או צרו איתנו קשר בשירות הלקוחות.
      <br />
      <a href="tel:058-6646125">058-6646125</a>
      <br />#{issueId}
    </div>
  );
};
export const notifyError = (id: number) => {
  rappelsend({
    mobile: "972546781721",
    text: `Client Error: ${id} ${API_BASE_URL}`,
  });
  toast.warn(<ErrorComponent issueId={id} />, {
    style: {
      textAlign: "right",
    },
  });
};

export const notifyCancelOrder = () => {
  toast.warn("ההזמנה בוטלה בהצלחה.", {
    style: {
      textAlign: "right",
    },
  });
};
