import styled from "@emotion/styled";
import { SelectUserShipment } from "../../components/SelectUserShipment";
import { useUserShipment } from "./useUserShipment";

const SelectRow = styled.div`
  width: 300px;
`;

export function SelectShipment() {
  useUserShipment();

  return (
    <>
      <SelectRow>
        <SelectUserShipment />
      </SelectRow>
    </>
  );
}
