import styled from "@emotion/styled";
import { Button } from "../../components/Button";
import { dataProvider } from "../../dp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPaymentLink, getStepperSlice } from "../../store/stepper/selectors";
import { resetThunk } from "../../store/thunk";
import { Errors } from "../../types";
import { notifyCancelOrder, notifyError } from "../../utils/notifications";
import { ButtonsFooterNav } from "../ButtonsFooterNav";

const Wrapper = styled.div``;

const Iframe = styled.iframe`
  width: 100%;
  flex: 1;
  border: none;
  height: 2500px;
  padding: 0;
`;
export const StepPayment = () => {
  const { orderId } = useAppSelector(getStepperSlice);
  const paymentLink = useAppSelector(getPaymentLink);
  const dispatch = useAppDispatch();

  return (
    <Wrapper>
      <ButtonsFooterNav>
        <h1>דף תשלום</h1>
        <Button
          onClick={() => {
            orderId &&
              dataProvider.orders
                .cancelOrder(orderId)
                .then(() => {
                  dispatch(resetThunk());
                  notifyCancelOrder();
                })
                .catch((err) => {
                  notifyError(Errors.CancelOrderFailed);
                });
          }}
        >
          ביטול הזמנה
        </Button>
      </ButtonsFooterNav>
      {paymentLink ? <Iframe src={paymentLink} scrolling="no" /> : "טוען..."}
    </Wrapper>
  );
};
