export const appTheme = {
  colors: {
    primary: "#829b73",
    // primary:  "#082e58",
    secondary: "#333",
    success: "#829b73",
  },
  fontSize: {
    0: "0.9rem",
    1: "1.1rem",
    2: "1.3rem",
  },
};
