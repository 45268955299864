import styled from "@emotion/styled";

import { appTheme } from "../../theme";

const StyledButton = styled.button<{
  variant?: "primary" | "secondary";
}>`
  background-color: ${(props) =>
    props.variant === "primary" ? appTheme.colors.success : "#fff"};
  border: 3px solid
    ${(props) =>
      props.variant === "primary"
        ? appTheme.colors.success
        : appTheme.colors.primary};
  color: ${(props) =>
    props.variant === "primary" ? "#fff" : appTheme.colors.primary};
  border-radius: 3px;

  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  max-width: 200px;
  &:disabled {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #999;
    cursor: not-allowed;
  }
`;
type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  variant?: "primary" | "secondary";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({
  children,
  onClick,
  disabled,
  variant = "primary",
}: ButtonProps) {
  return (
    <StyledButton
      className="button"
      onClick={onClick}
      disabled={disabled}
      variant={variant}
    >
      {children}
    </StyledButton>
  );
}
