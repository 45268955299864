import styled from "@emotion/styled";
import { Checkbox } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { Button } from "../../components/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { setCurrentStep } from "../../store/stepper/steperSlice";
import { ShipmentMethod, Steps } from "../../types";
import { ButtonsFooterNav } from "../ButtonsFooterNav";
import { useOrder } from "../hooks/useOrder";
import { DeliverySummary } from "./DeliverySummary";
import { PaymentSummary } from "./PaymentSummary";
import { PickupSummary } from "./PickupSummary";

const Wrapper = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  text-decoration: underline;
`;
export const StepSummary = () => {
  const { handleSubmit } = useForm<{ note: string }>();
  const { createOrder } = useOrder();
  const { shipmentMethod } = useAppSelector(getStepperSlice);
  const dispatch = useAppDispatch();
  const [agreement, setAgreement] = useState(false);

  const onSubmit = ({ note }: { note: string }) => {
    createOrder(note);
    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      window.fbq("track", "Purchase", {
        currency: "ILS",
        value: 100.0,
      });
    }
  };
  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <h1>סיכום הזמנה</h1>
      <PaymentSummary />
      {shipmentMethod === ShipmentMethod.DELIVERY && <DeliverySummary />}
      {shipmentMethod === ShipmentMethod.PICKUP && <PickupSummary />}
      {/* {shipmentMethod !== ShipmentMethod.SELF && (
        <FormInput placeholder="הוסף הערה למשלוח" {...register("note")} />
      )} */}
      <div>
        <Checkbox
          onChange={() => {
            setAgreement(!agreement);
          }}
        >
          קראתי ואני מאשר את
        </Checkbox>
        <StyledLink to="/terms" target="_blank">
          התקנון
        </StyledLink>
      </div>
      <ButtonsFooterNav>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(setCurrentStep(Steps.SELECT_SHIPMENT));
          }}
        >
          חזור לבחירת משלוח
        </Button>
        <Button disabled={!agreement} type="submit">
          סיום הזמנה
        </Button>
      </ButtonsFooterNav>
    </Wrapper>
  );
};
