import { City, PickupPoint } from "../types";
import { http } from "../utils/http";

export const cities = {
  getCities: () =>
    http.get<City[]>(`/cities`).then((response) => response.data),
  getShipmentCities: () =>
    http.get<City[]>(`/cities/shipments`).then((response) => response.data),
  getPickupPoints: async (cityId: string) =>
    http
      .get<PickupPoint[]>(`/pickups?cityId=${cityId}`)
      .then((response) => response.data),
};
