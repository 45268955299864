import styled from "@emotion/styled";
import ArrowForwardIos from "@mui/icons-material/NavigateNextSharp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { setCurrentStep } from "../../store/stepper/steperSlice";
import { appTheme } from "../../theme";
import { Steps } from "../../types";
import { useMatch } from "react-router-dom";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const StyledBackButton = styled.button`
  display: flex;
  background: none;
  border: none;
  outline: none;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  &:disabled {
    color: #ccc;
  }
  font-size: ${appTheme.fontSize[1]};
`;

const shouldBeVisble = (currentStep: Steps) => {
  switch (currentStep) {
    case Steps.SELECT_SHIPMENT:
    case Steps.SUMMARY:
    case Steps.ASK_FOR_NAME:
      return true;
    default:
      return false;
  }
};

export function BackButton() {
  const { currentStep } = useAppSelector(getStepperSlice);
  const dispatch = useAppDispatch();
  const match = useMatch({ path: "/success" });
  const handleGoBack = () => {
    switch (currentStep) {
      case Steps.SELECT_SHIPMENT:
        dispatch(setCurrentStep(Steps.SELECT_PRODUCTS));
        break;
      case Steps.SUMMARY:
        dispatch(setCurrentStep(Steps.SELECT_SHIPMENT));
        break;

      case Steps.ASK_FOR_NAME:
        dispatch(setCurrentStep(Steps.WELCOME));
        break;
    }
  };
  return match ? null : (
    <Wrapper>
      {shouldBeVisble(currentStep) ? (
        <StyledBackButton onClick={handleGoBack}>
          אחורה
          <ArrowForwardIos />
        </StyledBackButton>
      ) : null}
    </Wrapper>
  );
}
