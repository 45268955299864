import styled from "@emotion/styled";
import { useEffect } from "react";

import { Button } from "../../components/Button";
import { ProductItem } from "../../components/Product";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { getProductsThunk } from "../../store/products";
import { getProducts } from "../../store/products/selectors";
import { setCurrentStep } from "../../store/stepper/steperSlice";
import { Steps } from "../../types";
import { Step } from "../Step";
import { getStepperSlice } from "../../store/stepper/selectors";

const SubTitle = styled.div``;

const SubTitleItem = styled.span`
  font-weight: 0;
`;

export function StepQuantity() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getProductsThunk());
  }, [dispatch]);
  const products = useAppSelector(getProducts);
  const { lineItems } = useAppSelector(getStepperSlice);

  return (
    <Step>
      <h1>שלב ההזמנה</h1>
      <SubTitle>
        <SubTitleItem>כעת ניתן לבחור את המארזים למשלוח</SubTitleItem>
      </SubTitle>
      {products
        .filter((p) => p.visible)
        .map((product) => {
          return <ProductItem key={product.id} product={product} />;
        })}
      <Button
        disabled={lineItems.length === 0}
        onClick={() => {
          dispatch(setCurrentStep(Steps.SELECT_SHIPMENT));
        }}
      >
        שלב הבא
      </Button>
    </Step>
  );
}
