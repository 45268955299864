import Select from "react-select";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPickupPointsDropdown } from "../../store/pickups/selectors";

import { getStepperSlice } from "../../store/stepper/selectors";
import { setPickupPointId } from "../../store/stepper/steperSlice";

export const SelectPoint = () => {
  const dispatch = useAppDispatch();
  const points = useAppSelector(getPickupPointsDropdown);
  const { pickupPointId } = useAppSelector(getStepperSlice);

  return (
    <>
      <Select
        options={points}
        menuPosition={"fixed"}
        placeholder="בחר נקודת איסוף"
        onChange={(point) => {
          dispatch(setPickupPointId(point?.value));
        }}
        value={points.find((point) => point.value === pickupPointId)}
      />
    </>
  );
};
