import { OptionalEmail } from "../../components/OptionalEmail";

export function SelectSelf() {
  return (
    <div
      style={{
        textAlign: "center",
        lineHeight: "2rem",
      }}
    >
      <h4>איסוף עצמי חינם מבית העסק</h4>
      <div>שתולים, רחוב המייסדים 136</div>
      <div>איסוף עצמי בתיאום מראש בלבד מול שירות הלקוחות</div>
      <div>
        <a
          href="tel:058-6646125"
          aria-label="טלפון לשירות לקוחות"
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
        >
          058-6646125
        </a>
      </div>
      <br />
      <OptionalEmail />
    </div>
  );
}
