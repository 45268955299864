import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Product } from "../../types";
import { dataProvider } from "../../dp";

type State = {
  data: Product[];
  isLoading: boolean;
};
const initialState: State = {
  data: [],
  isLoading: false,
};

export const getProductsThunk = createAsyncThunk(
  "products/getProducts",
  async () => {
    try {
      const products = await dataProvider.products.getProducts();
      return products;
    } catch (error) {
      return [];
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductsThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
  },
});
