import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Iframe = styled.iframe`
  width: 100%;
  flex: 1;
  border: none;
  height: 100%;
  padding: 0;
`;

export function Terms() {
  return (
    <Wrapper>
      <Iframe src="https://itadmit.com/%d7%aa%d7%a7%d7%a0%d7%95%d7%9f-%d7%a6%d7%99%d7%95%d7%9f-%d7%97%d7%9e%d7%95%d7%a6%d7%99%d7%9d/" />
    </Wrapper>
  );
}
