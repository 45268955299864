import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface State {
  selectedCityId?: string;
}

const initialState: State = {
  selectedCityId: undefined,
};

export const uiSlice = createSlice({
  name: "uiSlice",
  initialState,
  reducers: {
    setSelectedCityId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCityId = action.payload;
    },

    resetUiState: () => {
      return initialState;
    },
  },
});

export const { setSelectedCityId, resetUiState } = uiSlice.actions;

export default uiSlice.reducer;
