import styled from "@emotion/styled";

export const Table = {
  Core: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  `,
  Row: styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-bottom: 1px dotted black;
  `,
  Cell: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 1rem;
    font-weight: bold;
    padding: 5px 0;
  `,
};
