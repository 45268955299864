import axios from "axios";
// WooCommerce API Endpoint
const apiUrl = "https://rappelsend.com/api/user/v2/send_message_url";

const clientId =
  "eyJ1aWQiOiJVUnpsY0dBdFFYMkNxRkloWmJCR2V1dGplQjdxTFBqciIsImNsaWVudF9pZCI6IjEifQ==";
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJVUnpsY0dBdFFYMkNxRkloWmJCR2V1dGplQjdxTFBqciIsInJvbGUiOiJ1c2VyIiwiaWF0IjoxNjk5MDM3NDE4fQ.IbJY3_sn3QbqgJuONFdl70OYBlYD9IyBM05A0kuqh0c";

const headers = {};

const rappelsendInstance = axios.create({
  baseURL: apiUrl,
  headers: headers,
});

type RappelsendType = {
  mobile: string;
  text: string;
};
export const rappelsend = ({ mobile, text }: RappelsendType) => {
  const queryParams = new URLSearchParams({
    client_id: clientId,
    mobile,
    text,
    token,
  });
  return rappelsendInstance.get(`?${queryParams}`);
};
