import { useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import "./styles.css";

export function Loader() {
  const { loading } = useAppSelector(getStepperSlice);
  return loading ? (
    <div className="loader">
      <div className="loader__spinner">טוען...</div>
    </div>
  ) : null;
}
