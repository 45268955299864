import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  CreateOrderPayload,
  Errors,
  OrderStatus,
  ShipmentMethod,
} from "../../types";

import { notifyError } from "../../utils/notifications";
import { getPickupPoints } from "../pickups/selectors";
import { getClosestPointByNow } from "../pickups/utils";
import { getProducts } from "../products/selectors";

export const getStepperSlice = (state: RootState) => state.stepper;

export const getStepperPickupPoint = createSelector(
  getPickupPoints,
  (state: RootState) => state.stepper.pickupPointId,
  (pickupPoints, stepperPickupPoints) => {
    const p = pickupPoints.find((p) => p.uuid === stepperPickupPoints);
    return p ? getClosestPointByNow(p) : undefined;
  }
);

export const getLineItems = createSelector(
  getProducts,
  getStepperSlice,
  (products, stepper) => {
    return stepper.lineItems.map((item) => {
      const product = products.find((p) => p.uuid === item.productId);
      return {
        ...item,
        product,
      };
    });
  }
);

export const getPaymentLink = (state: RootState) => state.stepper.paymentLink;

export const getOrder = createSelector(
  getStepperSlice,
  getProducts,
  getStepperPickupPoint,
  (steper, products, point): CreateOrderPayload | undefined => {
    const {
      shipmentMethod,
      fullName,
      phone,
      userId,
      lineItems,
      pickupPointId,
      userShipmentId,
    } = steper;

    switch (shipmentMethod) {
      case ShipmentMethod.PICKUP: {
        if (
          !fullName ||
          !phone ||
          !userId ||
          lineItems.length <= 0 ||
          !pickupPointId ||
          !point?.date
        ) {
          notifyError(Errors.GetPickupOrderDetails);
          return undefined;
        }
        return {
          userId,
          status: OrderStatus.PENDING,
          shipmentMethod: ShipmentMethod.PICKUP,
          pickupPointId: pickupPointId,
          pickupDate: point?.date,
          lineItems: lineItems.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            userId,
          })),
        };
      }
      case ShipmentMethod.DELIVERY: {
        const shipping = products.find((p) => p.title.includes("משלוח"));
        if (
          !fullName ||
          !phone ||
          !userId ||
          lineItems.length <= 0 ||
          !userShipmentId ||
          !shipping
        ) {
          notifyError(Errors.GetDeliveryOrderDetails);
          return undefined;
        }
        return {
          userId,
          status: OrderStatus.PENDING,
          shipmentMethod: ShipmentMethod.DELIVERY,
          userShipmentId,
          lineItems: lineItems
            .map((item) => ({
              productId: item.productId,
              quantity: item.quantity,
              userId,
            }))
            .concat({
              productId: shipping.uuid,
              quantity: 1,
              userId,
            }),
        };
      }
      case ShipmentMethod.SELF: {
        if (!fullName || !phone || !userId || lineItems.length <= 0) {
          notifyError(Errors.GetSelfOrderDetails);
          return undefined;
        }
        return {
          userId,
          status: OrderStatus.PENDING,
          shipmentMethod: ShipmentMethod.SELF,
          lineItems: lineItems.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            userId,
          })),
        };
      }
    }
  }
);
