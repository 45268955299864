import dayjs from "dayjs";
import { getSelectedCity } from "../../store/cities/selectors";
import { useAppSelector } from "../../store/hooks";
import { getSelectedPickupPoint } from "../../store/pickups/selectors";
import { Table } from "./commons";

export function PickupSummary() {
  const selectedPickupPoint = useAppSelector(getSelectedPickupPoint);
  const selectedCity = useAppSelector(getSelectedCity);

  return (
    <>
      <h3>פרטי נקודת איסוף</h3>
      <Table.Core>
        <Table.Row>
          <Table.Cell>עיר</Table.Cell>
          <Table.Cell>{selectedCity?.label}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>שם הנקודה</Table.Cell>
          <Table.Cell>{selectedPickupPoint?.label}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>תאריך</Table.Cell>
          <Table.Cell>
            {dayjs(selectedPickupPoint?.date).format("DD/MM/YYYY ביום dddd")}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>כתובת</Table.Cell>
          <Table.Cell>{selectedPickupPoint?.address}</Table.Cell>
        </Table.Row>
      </Table.Core>
    </>
  );
}
