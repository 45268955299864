import styled from "@emotion/styled";
import { InputHTMLAttributes, forwardRef } from "react";

const StyledInput = styled.input`
  padding: 15px;
  width: 100%;
  align-items: center;
  cursor: default;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: black;
  flex: 1;
  &::placeholder {
    color: black;
    font-weight: bold;
  }
`;

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export const FormInput = forwardRef(function FormInput(
  { ...props }: InputProps,
  ref: any
) {
  return <StyledInput {...props} ref={ref} />;
});
