import axios from "axios";
import { API_BASE_URL } from "../config";

const apiUrl = API_BASE_URL;
const headers = {};

export const http = axios.create({
  baseURL: apiUrl,
  headers: headers,
});
