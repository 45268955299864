import styled from "@emotion/styled";
import { SelectCity } from "../../components/SelectCity";
import { SelectPatmentMethod } from "../../components/SelectPatmentMethod";
import { SelectPoint } from "../../components/SelectPickup";
import { useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { PaymentMethod } from "../../types";
import { CashNote } from "./CashNote";
import { SelectedPickupPointInfo } from "./SelectedPickupPointInfo";

const SelectRow = styled.div`
  width: 300px;
`;

export function SelectPickUp() {
  const { pickupPointId, paymentMethod } = useAppSelector(getStepperSlice);
  return (
    <>
      <SelectRow>
        <SelectCity />
      </SelectRow>
      <SelectRow>
        <SelectPoint />
      </SelectRow>

      {pickupPointId && <SelectPatmentMethod />}
      {paymentMethod === PaymentMethod.CASH && <CashNote />}
      {/* {paymentMethod === PaymentMethod.CREDIT && pickupPointId && (
        <OptionalEmail />
      )} */}

      <SelectedPickupPointInfo />
    </>
  );
}
