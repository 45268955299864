import styled from "@emotion/styled";
import { Button } from "../../components/Button";
import { SelectShipmentMethod } from "../../components/SelectShipmentMethod";
import { getSelectedCityId } from "../../store/cities/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentStep } from "../../store/stepper/steperSlice";

import { getStepperSlice } from "../../store/stepper/selectors";
import { ShipmentMethod, Steps } from "../../types";
import { ButtonsFooterNav } from "../ButtonsFooterNav";
import { Step } from "../Step";
import { SelectPickUp } from "./SelectPickup";
import { SelectShipment } from "./SelectShipment";
import { SelectSelf } from "./SelectSelf";

const SelectRow = styled.div`
  width: 300px;
`;

export function StepStepSelectShipment() {
  const dispatch = useAppDispatch();
  const selectedCityId = useAppSelector(getSelectedCityId);
  const { shipmentMethod } = useAppSelector(getStepperSlice);
  const { pickupPointId, userShipmentId } = useAppSelector(getStepperSlice);
  return (
    <Step>
      <h1>תיאום משלוח</h1>
      <p>כמעט סיימנו! נא לבחור שיטת משלוח.</p>
      <SelectRow>
        <SelectShipmentMethod />
      </SelectRow>

      {shipmentMethod === ShipmentMethod.DELIVERY && <SelectShipment />}
      {shipmentMethod === ShipmentMethod.PICKUP && <SelectPickUp />}
      {shipmentMethod === ShipmentMethod.SELF && <SelectSelf />}
      
      <br />
      <ButtonsFooterNav>
        <Button
          aria-label="חזור לבחירת מוצרים"
          variant="secondary"
          onClick={() => {
            dispatch(setCurrentStep(Steps.SELECT_PRODUCTS));
          }}
        >
          חזור לבחירת מוצרים
        </Button>
        <Button
          aria-label="המשך לשלב הבא"
          disabled={
            !shipmentMethod ||
            (shipmentMethod === ShipmentMethod.DELIVERY && !userShipmentId) ||
            (shipmentMethod === ShipmentMethod.PICKUP && !selectedCityId) ||
            (shipmentMethod === ShipmentMethod.PICKUP && !pickupPointId)
          }
          onClick={() => {
            dispatch(setCurrentStep(Steps.SUMMARY));
          }}
        >
          המשך לשלב הבא
        </Button>
      </ButtonsFooterNav>
    </Step>
  );
}
