import { configureStore } from "@reduxjs/toolkit";
import { citiesSlice } from "./cities";
import { productsSlice } from "./products";
import steperSlice from "./stepper/steperSlice";
import uiSlice from "./ui/uiSlice";
import { userShipmentsSlice } from "./userShipments";
import { pickupsSlice } from "./pickups";

export const store = configureStore({
  reducer: {
    stepper: steperSlice,
    ui: uiSlice,
    products: productsSlice.reducer,
    userShipments: userShipmentsSlice.reducer,
    cities: citiesSlice.reducer,
    pickups: pickupsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
