import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";
import { dataProvider } from "../../dp";
import { Errors, UserShipment } from "../../types";
import { notifyError } from "../../utils/notifications";
import { getStepperSlice } from "../stepper/selectors";

type State = {
  update?: UserShipment;
  isLoading: boolean;
  data: UserShipment[];
};

const initialState: State = {
  isLoading: false,
  data: [],
};

export const getUserShipmentsThunk = createAsyncThunk(
  "userShipments/getUserShipments",
  async (_, options) => {
    const state = options.getState() as RootState;
    const { userId } = getStepperSlice(state);
    try {
      if (!userId) {
        notifyError(Errors.GetShipmentsFaild);
        return state.userShipments.data;
      }
      return await dataProvider.userShipments.getUserShipments(userId);
    } catch (error) {
      notifyError(Errors.GetShipmentsFaild);
      return state.userShipments.data;
    }
  }
);

export const createUserShipmentThunk = createAsyncThunk(
  "userShipments/createUserShipment",
  async (shipment: Omit<UserShipment, "id" | "uuid">, options) => {
    const state = options.getState() as RootState;
    const { userId } = getStepperSlice(state);
    try {
      if (!userId) {
        notifyError(Errors.CreateUserShipmentTunk);
        return;
      }
      return await dataProvider.userShipments.createUserShipment({
        ...shipment,
        userId,
      });
    } catch (error) {
      notifyError(Errors.CreateUserShipmentTunk);
    }
  }
);

export const updateUserShipmentThunk = createAsyncThunk(
  "userShipments/updateUserShipment",
  async ({ id, uuid, ...shipment }: UserShipment, options) => {
    try {
      return await dataProvider.userShipments.updateUserShipment(id, shipment);
    } catch (error) {
      notifyError(Errors.UpdateUserShipmentTunk);
    }
  }
);

export const userShipmentsSlice = createSlice({
  name: "userShipments",
  initialState,
  reducers: {
    setUpdateShipment: (state, action) => {
      state.update = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserShipmentsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserShipmentsThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUserShipmentsThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createUserShipmentThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUserShipmentThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.data.push(action.payload);
      }
      state.isLoading = false;
    });
  },
});

export const { setUpdateShipment } = userShipmentsSlice.actions;
