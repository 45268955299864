import { useState } from "react";

import styled from "@emotion/styled";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { useCustomer } from "../hooks/useCustomer";

const StyledBg = styled.div`
  background-image: url("/bg.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
  height: 100%;
  position: relative;
`;

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  gap: 20px;
  padding: 10px;
  font-size: 1.2rem;
`;
const handlePhoneNumberChange = (phone: string) =>
  phone.replace(/\D/g, "").slice(0, 10);

export function StepWelcome() {
  const [phone, setPhone] = useState("");
  const { validateCustomerByNumber } = useCustomer();

  return (
    <StyledBg>
      <StyledContent>
        <h1>ברוכים הבאים</h1>
        <p>על מנת לבצע הזמנה ותיאום משלוח נא להכניס את מספר הטלפון</p>
        <Input
          type="number"
          placeholder="☎️ מספר הטלפון שלך"
          pattern="[0-9]*"
          inputmode="numeric"
          value={phone}
          onChange={(number) => {
            setPhone(handlePhoneNumberChange(number));
          }}
          style={{
            maxWidth: "400px",
            minHeight: "54px",
            display: "flex",
            alignItems: "center",
            fontSize: "1.3rem",
            opacity: 0.8,
            textAlign: "center",
            fontWeight: "bold",
            color: "black",
          }}
        />

        <Button
          disabled={phone.length < 10}
          onClick={() => {
            validateCustomerByNumber(phone);
          }}
        >
          שלב הבא
        </Button>
      </StyledContent>
    </StyledBg>
  );
}
