// const FooterNav = styled.div`
//   display: flex;
//   gap: 25px;
//   a {
//     text-decoration: underline;
//   }
// `;

export function Footer() {
  return (
    <div
      style={{
        direction: "rtl",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {/* <img src="/ssl.png" alt="SSL" width={230} /> */}
      🇮🇱💪🇮🇱 !עם ישראל חיי 🇮🇱💪🇮🇱
      <div>
        <a
          href="tel:058-6646125"
          style={{
            color: "#fff",
          }}
          aria-label="טלפון לשירות לקוחות"
        >
          058-6646125 -
        </a>{" "}
        שירות לקוחות 10:00-17:00
      </div>
      {/* <FooterNav>
        <NavLink to="about">אודות</NavLink>
        <NavLink to="terms">תקנון</NavLink>
        <NavLink to="contactUs">צור קשר</NavLink>
      </FooterNav> */}
    </div>
  );
}
