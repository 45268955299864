import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { setPaymentMethod } from "../../store/stepper/steperSlice";
import { PaymentMethod } from "../../types";

const Methods = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 300px;
  gap: 20px;
`;

const Method = styled.div<{ active: boolean }>`
  padding: 10px;
  gap: 5px;
  display: flex;
  flex: 1;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  border: 3px solid #ccc;
  box-sizing: content-box;
  text-align: center;
  ${({ active }) => active && "border: 3px solid green"}
`;
export function SelectPatmentMethod() {
  const { paymentMethod } = useAppSelector(getStepperSlice);
  const dispatch = useAppDispatch();
  return (
    <Methods>
      {/* <Method
        active={paymentMethod === PaymentMethod.CREDIT}
        onClick={() => {
          dispatch(setPaymentMethod(PaymentMethod.CREDIT));
        }}
      >
        אשראי/ביט
        <img src="/bit.png" alt="סמל של ביט" width={25} />
      </Method> */}
      <Method
        active={paymentMethod === PaymentMethod.CASH}
        onClick={() => {
          dispatch(setPaymentMethod(PaymentMethod.CASH));
        }}
      >
        מזומן בעת קבלת המשלוח
      </Method>
    </Methods>
  );
}
