import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getStepperSlice } from "../../store/stepper/selectors";
import { setOptionalEmail } from "../../store/stepper/steperSlice";
import { Input } from "../Input";

export function OptionalEmail() {
  const dispatch = useAppDispatch();
  const { email } = useAppSelector(getStepperSlice);
  return (
    <Input
      onChange={(val) => {
        dispatch(setOptionalEmail(val));
      }}
      value={email || ""}
      type="email"
      placeholder="אימייל לשליחת קבלה (לא חובה)"
      style={{
        width: 300,
        margin: "10px 0",
      }}
    />
  );
}
