import { Loader } from "../components/Loader";
import { useAppSelector } from "../store/hooks";

import { getStepperSlice } from "../store/stepper/selectors";
import { Steps } from "../types";
import { StepAddUserShipment } from "./StepAddUserShipment";
import { StepAskForName } from "./StepAskForName";
import { StepPayment } from "./StepPayment";
import { StepQuantity } from "./StepQuantity";
import { StepStepSelectShipment } from "./StepSelectShipment";
import { StepSummary } from "./StepSummary";
import { StepUpdateUserShipment } from "./StepUpdateUserShipment";

import { StepWelcome } from "./StepWelcome";

export function Steper() {
  const steper = useAppSelector(getStepperSlice);

  const StepPage = () => {
    switch (steper.currentStep) {
      case Steps.WELCOME:
        return <StepWelcome />;
      case Steps.ASK_FOR_NAME:
        return <StepAskForName />;
      case Steps.SELECT_PRODUCTS:
        return <StepQuantity />;
      case Steps.SELECT_SHIPMENT:
        return <StepStepSelectShipment />;
      case Steps.ADD_USER_SHIMPENT:
        return <StepAddUserShipment />;
      case Steps.UPDATE_USER_SHIPMENT:
        return <StepUpdateUserShipment />;
      case Steps.SUMMARY:
        return <StepSummary />;
      case Steps.PAYMENT:
        return <StepPayment />;
      default:
        return <StepWelcome />;
    }
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <Loader />
      {StepPage()}
    </div>
  );
}
