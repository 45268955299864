import { useEffect } from "react";
import Select, { SingleValue } from "react-select";
import { getShipmentCitiesThunk } from "../../store/cities";
import { getShipmentCities } from "../../store/cities/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { City } from "../../types";

type SelectCityProps = {
  onChane?: (city: SingleValue<City>) => void;
  selctedCityId?: string;
};

export const SelectShipmentCity = ({
  onChane,
  selctedCityId,
}: SelectCityProps) => {
  const cities = useAppSelector(getShipmentCities);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getShipmentCitiesThunk());
  }, [dispatch]);
  return (
    <>
      <Select
        required
        aria-label="בחר עיר"
        options={cities}
        menuPosition={"fixed"}
        placeholder="בחר עיר"
        onChange={(option) => {
          onChane && onChane(option);
        }}
        value={cities.find((city) => city.uuid === selctedCityId)}
      />
    </>
  );
};
