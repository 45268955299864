import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LineItem, PaymentMethod, ShipmentMethod, Steps } from "../../types";

interface SteperSlice {
  currentStep: Steps;
  phone?: string;
  fullName?: string;
  userId?: string;
  lineItems: Pick<LineItem, "productId" | "quantity">[];
  loading?: boolean;
  pickupPointId?: string;
  userShipmentId?: string;
  orderId?: string;
  paymentLink?: string;
  shipmentMethod?: ShipmentMethod;
  email?: string;
  paymentMethod: PaymentMethod;
}

const initialState: SteperSlice = {
  currentStep: Steps.WELCOME,
  phone: undefined,
  fullName: undefined,
  userId: undefined,
  lineItems: [],
  pickupPointId: undefined,
  orderId: undefined,
  paymentLink: undefined,
  userShipmentId: undefined,
  shipmentMethod: undefined,
  paymentMethod: PaymentMethod.CASH,
};

export const steperSlice = createSlice({
  name: "steperSlice",
  initialState,
  reducers: {
    setCustomerName: (state, action) => {
      state.fullName = action.payload;
    },
    setCustomerPhone: (state, action) => {
      state.phone = action.payload;
    },
    setuserId: (state, action) => {
      state.userId = action.payload;
    },
    setLineItems: (state, action) => {
      state.lineItems = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPickupPointId: (state, action: PayloadAction<string | undefined>) => {
      state.pickupPointId = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    setResetStepper: () => {
      return initialState;
    },
    setPaymentLink: (state, action) => {
      state.paymentLink = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<Steps>) => {
      state.currentStep = action.payload;
    },
    setShipmentMethod: (
      state,
      action: PayloadAction<ShipmentMethod | undefined>
    ) => {
      state.shipmentMethod = action.payload;
    },
    setUserShipmentId: (state, action: PayloadAction<string | undefined>) => {
      state.userShipmentId = action.payload;
    },
    setOptionalEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
    },
  },
});

export const {
  setCustomerName,
  setCustomerPhone,
  setuserId,
  setLineItems,
  setLoading,
  setOrderId,
  setResetStepper,
  setPickupPointId,
  setPaymentLink,
  setCurrentStep,
  setShipmentMethod,
  setUserShipmentId,
  setOptionalEmail,
  setPaymentMethod,
} = steperSlice.actions;

export default steperSlice.reducer;
