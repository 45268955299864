import styled from "@emotion/styled";

const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 20px;
`;

type StepProps = {
  children: React.ReactNode;
};

export function Step({ children }: StepProps) {
  return <StyledStep className="step">{children}</StyledStep>;
}
