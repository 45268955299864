import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

export function About() {
  return (
    <Wrapper>
      <h1>אודותינו</h1>
      <p>
        ציון חמוצים הינה חברה הממוקמת בדרום הארץ עם נסיון של מעל 50 שנה בייצור
        חמוצים, זיתים ומטבלים.
      </p>
    </Wrapper>
  );
}
