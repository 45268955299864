import styled from "@emotion/styled";
import { appTheme } from "../../theme";
import { BackButton } from "./BackButton";
import { NextButton } from "./NextButton";

const StyledHeader = styled.header`
  background-color: ${appTheme.colors.primary};
  flex: 1;
  display: grid;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-height: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 1000;
  h1 {
    font-size: ${appTheme.fontSize[2]};
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function Header() {
  return (
    <StyledHeader aria-label="כותרת האתר">
      <NextButton />
      <Logo>
        <img
          src="https://i.imgur.com/wrJoBdt.png"
          alt="לוגו ציון חמוצים"
          width={100}
        />
      </Logo>
      <BackButton />
    </StyledHeader>
  );
}
