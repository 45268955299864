"use client";

import { Provider } from "react-redux";
import { store } from ".";
import { BrowserRouter } from "react-router-dom";

export function ReduxProvider({ children }: any) {
  return (
    <Provider store={store}>
      <BrowserRouter>{children}</BrowserRouter>
    </Provider>
  );
}
