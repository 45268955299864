import { cities } from "./cities";
import { customer } from "./customer";
import { orders } from "./orders";
import { payments } from "./payments";
import { products } from "./products";
import { userShipments } from "./userShipments";

export const dataProvider = {
  customer,
  products,
  userShipments,
  cities,
  orders,
  payments,
};
