import { UserShipment } from "../types";
import { http } from "../utils/http";

export const userShipments = {
  getUserShipments: async (userId: string) =>
    http
      .get<UserShipment[]>(`/shipments/${userId}`)
      .then((response) => response.data),
  createUserShipment: async (shipment: Omit<UserShipment, "uuid" | "id">) =>
    http
      .post<UserShipment>(`/shipments`, shipment)
      .then((response) => response.data),
  updateUserShipment: async (
    id: number,
    shipment: Omit<UserShipment, "uuid" | "id">
  ) =>
    http
      .put<UserShipment>(`/shipments/${id}`, shipment)
      .then((response) => response.data),
};
