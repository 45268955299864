import styled from "@emotion/styled";
import Done from "@mui/icons-material/CheckCircle";
import { parse } from "qs";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentStep } from "../../store/stepper/steperSlice";
import { resetThunk } from "../../store/thunk";
import { appTheme } from "../../theme";
import { Steps } from "../../types";
import { getStepperSlice } from "../../store/stepper/selectors";

const StyledComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
  font-size: ${appTheme.fontSize[2]};
  font-weight: 700;
  padding: 30px;
`;

const StyledNote = styled.div`
  font-size: ${appTheme.fontSize[1]};
  font-weight: lighter;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLink = styled.a`
  color: ${appTheme.colors.primary};
  text-decoration: underline;
`;
export function StepComplete() {
  const { phone } = useAppSelector(getStepperSlice);
  const navigate = useNavigate();
  const { more_info_2 } = parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as { more_info_2: string };

  const dispatch = useAppDispatch();

  return (
    <StyledComplete>
      <Done
        style={{
          fontSize: "3rem",
          color: "#4ab24a",
        }}
      />
      איזה כיף ההזמנה הושלמה בהצלחה!
      <div>מספר הזמנה: {more_info_2}#</div>
      <StyledNote>
        ברגעים אלו תתקכל הודעה עם פרטי הזמנה,
        <b>למספרך: {phone}</b>
        לא קיבלת? יש טעות במספר? חייגו לשירות הלקוחות{" "}
        <StyledLink href="tel:058-6646125" aria-label="טלפון לשירות לקוחות">
          058-6646125
        </StyledLink>
        <br />
        שעות הפעילות שלנו: א׳-ה׳ 10:00-17:00
      </StyledNote>
      <Button
        onClick={async () => {
          await dispatch(resetThunk());
          await dispatch(setCurrentStep(Steps.WELCOME));
          navigate("/");
        }}
      >
        להזמנה חדשה
      </Button>
    </StyledComplete>
  );
}
