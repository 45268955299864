import styled from "@emotion/styled";
import { appTheme } from "../../theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
const StyledLink = styled.a`
  font-size: ${appTheme.fontSize[2]};
  color: ${appTheme.colors.primary};
  text-decoration: underline;
`;

export function ContactUs() {
  return (
    <Wrapper>
      <h1>צור קשר</h1>
      ניתן ליצור קשר בטלפון של שירות הלקוחות
      <StyledLink href="tel:058-6646125" aria-label="טלפון לשירות לקוחות">
        058-6646125
      </StyledLink>
      <br />
      שעות הפעילות שלנו: א׳-ה׳ 10:00-17:00
    </Wrapper>
  );
}
