import styled from "@emotion/styled";
import { appTheme } from "../../theme";

export const Layout = {
  Core: styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
  `,
  Main: styled.main`
    display: flex;
    flex-direction: column;

    direction: rtl;
    flex: 1;
  `,
  Header: styled.header`
    flex: 0 0 auto;
    color: #fff;
    display: flex;
    height: 70px;
  `,
  Footer: styled.footer`
    flex: 0 0 auto;
    padding: 10px;
    background-color: ${appTheme.colors.primary};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
