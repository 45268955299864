import styled from "@emotion/styled";
import { useAppSelector } from "../../store/hooks";
import { getLineItems, getStepperSlice } from "../../store/stepper/selectors";

import { appTheme } from "../../theme";
import { getPrice } from "../../utils/helpers";
import { ShipmentMethod } from "../../types";

const Wrapper = styled.div`
  border: 1px solid black;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 3px;
`;

const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  direction: rtl;
`;

const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  border-bottom: 1px dotted black;
  &:last-child:not(:first-of-type) {
    border-bottom: none;
  }
  font-size: ${appTheme.fontSize[1]};
`;

const TableCellProperty = styled.td`
  flex: 1;
  padding: 5px;
  display: flex;
  gap: 5px;
  direction: rtl;
`;
export function PaymentSummary() {
  const lineItems = useAppSelector(getLineItems);
  const { shipmentMethod } = useAppSelector(getStepperSlice);
  return (
    <Wrapper>
      <Table>
        <thead>
          <TableRow>
            <TableCellProperty>מוצר</TableCellProperty>
            <TableCellProperty>מחיר ליחידה</TableCellProperty>
            <TableCellProperty>כמות</TableCellProperty>
            <TableCellProperty>סה״כ</TableCellProperty>
          </TableRow>
        </thead>
        <tbody>
          {lineItems.map((lineItem) => (
            <TableRow key={lineItem.productId}>
              <TableCellProperty>{lineItem.product?.title}</TableCellProperty>
              <TableCellProperty>
                {getPrice(lineItem.product)}
                <span>₪</span>
              </TableCellProperty>
              <TableCellProperty>{lineItem.quantity}</TableCellProperty>
              <TableCellProperty>
                {(getPrice(lineItem.product) || 0) * lineItem.quantity}
                <span>₪</span>
              </TableCellProperty>
            </TableRow>
          ))}
          {shipmentMethod === ShipmentMethod.DELIVERY && (
            <TableRow>
              <TableCellProperty>משלוח</TableCellProperty>
              <TableCellProperty></TableCellProperty>
              <TableCellProperty></TableCellProperty>
              <TableCellProperty>
                25
                <span>₪</span>
              </TableCellProperty>
            </TableRow>
          )}
          <TableRow>
            <TableCellProperty></TableCellProperty>
            <TableCellProperty></TableCellProperty>
            <TableCellProperty></TableCellProperty>
            <TableCellProperty>
              {lineItems.reduce(
                (acc, lineItem) =>
                  acc + getPrice(lineItem.product) * lineItem.quantity,
                shipmentMethod === ShipmentMethod.DELIVERY ? 25 : 0
              )}
              <span>₪</span>
            </TableCellProperty>
          </TableRow>
        </tbody>
      </Table>
    </Wrapper>
  );
}
